import { useStore } from "vuex";
import { Role } from "@/@types/Role";
import { ROLE } from "@/constants/roles";

export default function (
    allowedRoles: Role[],
    organisationId?: number,
): { canAccess: boolean; organisationMatches: boolean } {
    const store = useStore();
    const isAdmin = store.state.user.user_organisation_role === ROLE.ADMIN.id;
    const canAccess =
        isAdmin || allowedRoles?.some((r) => r.id === store.state.user.user_organisation_role);
    const organisationMatches = store.state.user.organisation.id === organisationId;

    return {
        canAccess,
        organisationMatches,
    };
}
