import { Commit } from "vuex";

import { openErrorNotification, openSuccessNotification } from "@/components/Notification";
import { Kayttaja, accessKayttaja } from "../@types/Kayttaja";

interface Kayttajat {
    kayttajat: Kayttaja[];
    isLoadingResults: boolean;
    isLoadingUser: boolean;
}

export default {
    namespaced: true,
    state: {
        kayttajat: [],
        isLoadingResults: false,
        isLoadingUser: false,
    },
    getters: {},
    mutations: {
        setKayttajat(state: Kayttajat, kayttajat: Kayttajat["kayttajat"]): void {
            state.kayttajat = kayttajat;
        },
        updateKayttaja(state: Kayttajat, updatedUser: Kayttaja): void {
            const user = {
                ...updatedUser,
                displayName: `${updatedUser.givenName} ${updatedUser.surname}`,
            };
            const index = state.kayttajat.findIndex((k: Kayttaja) => k.id === user?.id);
            if (index !== -1) {
                const newArr = [...state.kayttajat];
                ["givenName", "surname", "displayName", "jobTitle", "mobilePhone"].forEach((t) => {
                    newArr[index][t as keyof accessKayttaja] = user[t as keyof accessKayttaja];
                });
                state.kayttajat = newArr;
            }
        },
        addKayttaja(state: Kayttajat, user: Kayttaja): void {
            const newUsers = [...state.kayttajat, user];
            state.kayttajat = newUsers;
        },
        deleteKayttaja(state: Kayttajat, id: string): void {
            const newUsers = state.kayttajat.filter((k: Kayttaja) => k.id !== id);
            state.kayttajat = newUsers;
        },
        setLoadingResults(state: Kayttajat, loading: boolean): void {
            state.isLoadingResults = loading;
        },
        setLoadingUser(state: Kayttajat, loading: boolean): void {
            state.isLoadingUser = loading;
        },
    },
    actions: {
        async getKayttajat({ commit }: { commit: Commit }, organisationId: string): Promise<void> {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            let url = `${process.env.VUE_APP_API_URL}/kayttaja`;
            if (organisationId) url = `${url}?organisationId=${organisationId}`;
            try {
                commit("setLoadingResults", true);

                const response = await fetch(url, requestOptions);
                if (response.status === 200) {
                    const kayttajat = await response.json();

                    commit("setKayttajat", kayttajat.results);
                    openSuccessNotification("Käyttäjät haettu onnistuneesti");
                } else throw new Error();
            } catch {
                openErrorNotification("Käyttäjien haku epäonnistui");
            } finally {
                commit("setLoadingResults", false);
            }
        },
        async saveKayttaja({ commit }: { commit: Commit }, kayttaja: Kayttaja): Promise<void> {
            const requestOptions = {
                method: "POST",
                body: JSON.stringify(kayttaja),
            };
            const url = `${process.env.VUE_APP_API_URL}/kayttaja`;
            try {
                commit("setLoadingUser", true);

                const response = await fetch(url, requestOptions);
                if (response.status === 200) {
                    const user = await response.json();
                    commit("addKayttaja", {
                        ...kayttaja,
                        id: user.id,
                        displayName: `${kayttaja.givenName} ${kayttaja.surname}`,
                    });
                    openSuccessNotification("Käyttäjä lisätty onnistuneesti");
                } else throw new Error();
            } catch {
                openErrorNotification("Käyttäjän lisääminen epäonnistui");
            } finally {
                commit("setLoadingUser", false);
            }
        },
        async editKayttaja({ commit }: { commit: Commit }, kayttaja: Kayttaja): Promise<void> {
            const requestOptions = {
                method: "PUT",
                body: JSON.stringify(kayttaja),
            };
            const url = `${process.env.VUE_APP_API_URL}/kayttaja`;
            try {
                commit("setLoadingUser", true);

                const response = await fetch(url, requestOptions);
                if (response.status === 200) {
                    commit("updateKayttaja", kayttaja);
                    openSuccessNotification("Käyttäjän muokkaus onnistui");
                } else throw new Error();
            } catch {
                openErrorNotification("Käyttäjän muokkaus epäonnistui");
            } finally {
                commit("setLoadingUser", false);
            }
        },
        async deleteKayttaja({ commit }: { commit: Commit }, id: string): Promise<void> {
            const url = `${process.env.VUE_APP_API_URL}/kayttaja?id=${id}`;
            const requestOptions = {
                method: "DELETE",
            };

            commit("setLoadingUser", true);
            try {
                const response = await fetch(url, requestOptions);

                if (response.status === 200) {
                    commit("deleteKayttaja", id);
                    openSuccessNotification("Käyttäjän poistaminen onnistui");
                } else throw new Error();
            } catch {
                openErrorNotification("Käyttäjän poistaminen epäonnistui");
            } finally {
                commit("setLoadingUser", false);
            }
        },
    },
};
