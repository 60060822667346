const Module = () => import("../Module.vue");
const Kayttaja = () => import(/* webpackChunkName: "kayttaja" */ "../views/Kayttaja.vue");
import { ROLE } from "@/constants/roles";

const moduleRoute = {
    path: "/kayttaja",
    name: "kayttaja",
    component: Module,
    children: [
        {
            path: "",
            component: Kayttaja,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [
            ROLE.KUNTAMANAGER,
            ROLE.VALVOJAMANAGER,
        ],
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
