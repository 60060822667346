<template>
    <q-header class="row app-bar q-mb-md justify-between items-center">
        <div class="col-auto">
            <img
                :src="require('@/assets/logo.svg')"
                class="logo clickable"
                alt="Lasterin logo"
                @click="toFrontPage"
            />
        </div>
        <div class="row col-6 justify-between items-center no-wrap header" v-if="requiresAuth">
            <div v-for="route in navigationRoutes" :key="route.path || route.name">
                <q-btn
                    v-if="route.path && isVisibleForRole(route.path)"
                    flat
                    :label="route.name"
                    no-caps
                    :to="`/${route.path}`"
                    color="dark-title"
                    :class="`link ${routeSelected(route.path)}`"
                />
                <q-btn
                    v-if="route.childRoutes && isVisibleForRole(routeChildPaths(route))"
                    color="dark-title"
                    flat
                    :label="route.name"
                    no-caps
                    :class="`link ${routeSelected(routeChildPaths(route))}`"
                >
                    <q-menu
                        v-if="route.childRoutes"
                        class="smaller-body user-menu navigation"
                        style="min-width: 220px"
                    >
                        <q-item v-for="childRoute in route.childRoutes" :key="childRoute.path">
                            <q-btn
                                v-if="isVisibleForRole(childRoute.path)"
                                flat
                                :label="childRoute.name"
                                no-caps
                                :to="`/${childRoute.path}`"
                                color="dark-title"
                                class="link"
                            />
                        </q-item>
                    </q-menu>
                </q-btn>
            </div>
        </div>
        <q-btn class="fab q-mr-lg" :label="userDetails.initials" v-if="requiresAuth">
            <q-menu class="smaller-body user-menu" style="min-width: 190px">
                <q-item class="column">
                    {{ userDetails.name }}
                    <div class="overline">{{ userDetails.email }}</div>
                </q-item>
                <q-item>{{ userRole?.name }}</q-item>
                <q-item>
                    <q-btn
                        color="dark-title"
                        icon="fas fa-sign-out-alt"
                        flat
                        label="Kirjaudu ulos"
                        no-caps
                        class="link"
                        @click="signOut"
                    />
                </q-item>
            </q-menu>
        </q-btn>
    </q-header>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Role } from "@/@types/Role";
import { ROLE } from "@/constants/roles";
import useAuthorization from "@/utils/useAuthorization";

interface Path {
    name: string;
    path?: string;
    childRoutes?: Path[];
}

export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        const allRoutes = computed(() => router.getRoutes());

        const isVisibleForRole = (routePath: string | string[]): boolean => {
            const arr = Array.isArray(routePath) ? routePath : [routePath];
            const routeByPathArr = allRoutes.value.filter((route) =>
                arr.includes(route.name as string),
            );
            const allAllowedRoles = routeByPathArr.map((r: any) => r.meta.allowedRoles).flat();
            const { canAccess } = useAuthorization(allAllowedRoles as Role[]);
            return canAccess;
        };

        const routeChildPaths = (route: Path): string[] =>
            route.childRoutes!.map((p) => p.path as string);

        const routeSelected = (routePath: string | string[]): string => {
            const arr = Array.isArray(routePath) ? routePath : [routePath];
            return arr.some((path) => router.currentRoute.value.path.includes(path))
                ? "header-btn-selected"
                : "";
        };

        const signOut = () => {
            window.location.href = process.env.VUE_APP_LOGOUT_URL;
        };

        const isPalveluntuottaja =
            store.state.user?.user_organisation_role === ROLE.PALVELUNTUOTTAJA.id;

        const navigationRoutes: Path[] = [
            { path: "palveluhaku", name: isPalveluntuottaja ? "Yksiköt" : "Palveluhaku" },
            { path: "palveluntuottaja", name: "Organisaatio" },
            { path: "valvontatiedot", name: "Valvontatiedot" },
            { path: "valvontakalenteri", name: "Valvontakalenteri" },
            { path: "organisaatiot", name: "Organisaatiot" },
            {
                name: "Kilpailutus- ja hankintatiedot",
                childRoutes: [
                    { path: "puitesopimukset", name: "Puitesopimukset" },
                    { path: "kilpailutusasiakirjat", name: "Kilpailutusasiakirjat" },
                ],
            },
            { path: "kayttaja", name: "Käyttäjähallinta" },
        ];

        const requiresAuth = computed(() => store.state.user);

        const userDetails = computed(() => {
            const { name, email, user_organisation_role } = store.state.user;
            const initials = name.split(" ").map((n: string) => n.charAt(0));
            return { initials: initials.join(""), name, email, user_organisation_role };
        });

        const userRole = computed(() =>
            Object.values(ROLE).find((v) => v.id === store.state.user.user_organisation_role),
        );

        const toFrontPage = () => {
            router.push("/etusivu");
        };

        return {
            signOut,
            userRole,
            userDetails,
            routeSelected,
            requiresAuth,
            isVisibleForRole,
            navigationRoutes,
            toFrontPage,
            routeChildPaths,
        };
    },
});
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
.logo {
    width: 138px;
}
.app-bar {
    background-color: $bg;
    width: 100%;
    box-shadow: 0px 1px 1px #a4aebc;
}
.fab {
    background-color: $primary-light-3;
    border-radius: 50%;
    height: 32px;
    min-height: 32px;
    width: 32px;
    font-size: 0.75rem;
    color: $dark-text;
}
</style>
