<template>
    <q-layout view="hhh LpR fff">
        <Header />
        <q-page-container>
            <slot />
        </q-page-container>
        <Footer />
    </q-layout>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/layouts/Header.vue";
import Footer from "@/layouts/Footer.vue";

export default defineComponent({
    components: { Header, Footer },
});
</script>
