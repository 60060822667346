<template>
    <div v-if="loading" class="column justify-center q-pt-xl items-center">
        <q-spinner-dots color="primary" size="4em" />
        <img :src="require('@/assets/logo.svg')" class="logo" alt="Lasterin logo" />
    </div>
    <AppLayout v-if="!loading">
        <router-view />
    </AppLayout>
</template>

<script lang="ts" scope>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    setup() {
        const store = useStore();

        const loading = computed(() => store.state.userLoading);

        return { loading };
    },
});
</script>

<style lang="scss">
@import "@/styles/global.scss";

.logo {
    width: 300px;
}
</style>
