import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import { Role } from "@/@types/Role";
import { ROLE } from "@/constants/roles";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/pages/Home.vue"),
        meta: {
            layout: "AppLayoutHome",
        },
    },
    {
        path: "/kayttoehdot",
        name: "TermsAndConditions",
        component: () => import("@/pages/Home.vue"),
        meta: {
            layout: "AppLayoutHome",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const { state } = store;
    if (!to.meta.requiresAuth) {
        next();
    }
    if (to.meta.requiresAuth && !state.userError && !state.user) {
        await store.dispatch("init");
    }

    if (store.state.userError) {
        next({ path: "/" });
    }

    const isAdmin = state.user?.user_organisation_role === ROLE.ADMIN.id;
    if (
        to.meta.requiresAuth &&
        (isAdmin ||
            ((to.meta.allowedRoles as Role[]) || []).some(
                (r) => r.id === state.user?.user_organisation_role,
            ))
    ) {
        next();
    } else {
        next({ path: "etusivu" });
    }
});

export default router;
