<template>
    <q-footer bordered class="row q-py-md items-center">
        <div v-for="link in routerLinks" :key="link" class="row item justify-center">
            <router-link
                v-if="linkIsVisible(link.private)"
                class="link text-dark-text"
                :target="linkTarget(link.newWindow)"
                :to="link.url"
            >
                {{ link.name }}
            </router-link>
        </div>
    </q-footer>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
    setup() {
        const routerLinks = [
            { name: "Tietosuojaseloste", url: "/pdf/tietosuojaseloste.pdf", newWindow: true },
            { name: "Saavutettavuusseloste", url: "/saavutettavuusseloste" },
            { name: "Käyttöohje", url: "/pdf/kaytto-ohje.pdf", private: true, newWindow: true },
        ];

        const route = useRoute();
        const requiresAuth = computed(() => route.meta.requiresAuth);

        const linkIsVisible = (isPrivate?: boolean) =>
            (isPrivate && requiresAuth.value) || !isPrivate;

        const linkTarget = (target?: boolean) => {
            if (target) return "_blank";
        };

        return { routerLinks, linkIsVisible, linkTarget };
    },
});
</script>
<style lang="scss" scoped>
@import "@/styles/variables";
footer {
    background-color: $bg;
    width: 100%;
}

.item {
    width: 30%;
    margin: 0 auto;
}

.link {
    font-size: 1rem;
    font-weight: 600;
}
</style>
