import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col-auto" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "row col-6 justify-between items-center no-wrap header"
}
const _hoisted_4 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_header = _resolveComponent("q-header")!

  return (_openBlock(), _createBlock(_component_q_header, { class: "row app-bar q-mb-md justify-between items-center" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('@/assets/logo.svg'),
          class: "logo clickable",
          alt: "Lasterin logo",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toFrontPage && _ctx.toFrontPage(...args)))
        }, null, 8, _hoisted_2)
      ]),
      (_ctx.requiresAuth)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationRoutes, (route) => {
              return (_openBlock(), _createElementBlock("div", {
                key: route.path || route.name
              }, [
                (route.path && _ctx.isVisibleForRole(route.path))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      flat: "",
                      label: route.name,
                      "no-caps": "",
                      to: `/${route.path}`,
                      color: "dark-title",
                      class: _normalizeClass(`link ${_ctx.routeSelected(route.path)}`)
                    }, null, 8, ["label", "to", "class"]))
                  : _createCommentVNode("", true),
                (route.childRoutes && _ctx.isVisibleForRole(_ctx.routeChildPaths(route)))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      color: "dark-title",
                      flat: "",
                      label: route.name,
                      "no-caps": "",
                      class: _normalizeClass(`link ${_ctx.routeSelected(_ctx.routeChildPaths(route))}`)
                    }, {
                      default: _withCtx(() => [
                        (route.childRoutes)
                          ? (_openBlock(), _createBlock(_component_q_menu, {
                              key: 0,
                              class: "smaller-body user-menu navigation",
                              style: {"min-width":"220px"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(route.childRoutes, (childRoute) => {
                                  return (_openBlock(), _createBlock(_component_q_item, {
                                    key: childRoute.path
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.isVisibleForRole(childRoute.path))
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            flat: "",
                                            label: childRoute.name,
                                            "no-caps": "",
                                            to: `/${childRoute.path}`,
                                            color: "dark-title",
                                            class: "link"
                                          }, null, 8, ["label", "to"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["label", "class"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.requiresAuth)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 1,
            class: "fab q-mr-lg",
            label: _ctx.userDetails.initials
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_menu, {
                class: "smaller-body user-menu",
                style: {"min-width":"190px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item, { class: "column" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.userDetails.name) + " ", 1),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.userDetails.email), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.userRole?.name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        color: "dark-title",
                        icon: "fas fa-sign-out-alt",
                        flat: "",
                        label: "Kirjaudu ulos",
                        "no-caps": "",
                        class: "link",
                        onClick: _ctx.signOut
                      }, null, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}