import { createStore, Commit } from "vuex";
import { getPalveluyksikot } from "@/modules/palveluhaku/api/palveluyksikot";
import { getUserDetails } from "@/api/user";
import { openErrorNotification, openSuccessNotification } from "@/components/Notification";
import { Option } from "@/@types/Option";
import { User } from "@/@types/User";
import { Organisation } from "@/@types/Organisation";
import { saveOrganisation, getOrganisations } from "@/modules/organisations/api/organisation";

export interface State {
    palveluyksikot: Option[];
    palveluyksikotLoading: boolean;
    user: User | null;
    organisations: Organisation[];
    organisationsLoading: boolean;
    userLoading: boolean;
    userError: boolean;
}

export default createStore<State>({
    state: {
        palveluyksikot: [],
        palveluyksikotLoading: false,
        user: null,
        organisations: [],
        organisationsLoading: true,
        userLoading: false,
        userError: false,
    },
    getters: {
        // https://vuex.vuejs.org/guide/getters.html#method-style-access
        getPalveluyksikkoById: (state) => (id: string) => {
            return state.palveluyksikot.find((py) => Number(py.id) === Number(id));
        },
    },
    mutations: {
        setPalveluyksikot(state: State, yksikot: Option[]) {
            state.palveluyksikot = yksikot;
        },
        setPalveluyksikotLoading(state: State, loading: boolean) {
            state.palveluyksikotLoading = loading;
        },
        setUser(state: State, user: User) {
            state.user = user;
        },
        setOrganisations(state: State, organisations: Organisation[]): void {
            state.organisations = organisations;
        },
        updateOrganisations(state: State, organisation: Organisation): void {
            const orgArr = [...state.organisations];
            orgArr.push(organisation);
            state.organisations = orgArr;
        },
        setOrganisationsLoading(state: State, loading: boolean) {
            state.organisationsLoading = loading;
        },
        setUserLoading(state: State, loading: boolean) {
            state.userLoading = loading;
        },
        setUserError(state: State, error: boolean) {
            state.userError = error;
        },
    },
    actions: {
        async fetchPalveluyksikot({ commit }: { commit: Commit }) {
            try {
                commit("setPalveluyksikotLoading", true);
                const palveluyksikot = await getPalveluyksikot();
                commit("setPalveluyksikot", palveluyksikot.results);
            } catch {
                openErrorNotification("Palveluyksiköiden hakeminen epäonnistui");
            } finally {
                commit("setPalveluyksikotLoading", false);
            }
        },

        async init({ dispatch }) {
            await Promise.all([dispatch("fetchUserDetails")]);
        },
        async fetchUserDetails({ commit }: { commit: Commit }) {
            let response;
            try {
                commit("setUserLoading", true);
                response = await getUserDetails();
                const userDetails = await response.json();
                commit("setUser", userDetails);
            } catch {
                let errorMsg;
                switch (response?.status) {
                    case 404:
                        errorMsg =
                            "Koita kirjautua sisään uudelleen hetken kuluttua, mikäli ongelma toistuu ota yhteys järjestelmävalvojaan.";
                        break;
                    case 401:
                        errorMsg = "Sinulla ei ole oikeuksia sivun katseluun.";
                        break;
                }
                commit("setUserError", true);
                openErrorNotification(`Käyttäjätietojen hakeminen epäonnistui. ${errorMsg || ""}`);
            } finally {
                commit("setUserLoading", false);
            }
        },
        async fetchOrganisations({ commit }: { commit: Commit }, valvoja?: boolean) {
            try {
                const response = await getOrganisations(valvoja);
                commit("setOrganisations", response.results);
            } catch {
                openErrorNotification("Organisaatioiden haku epäonnistui");
            } finally {
                commit("setOrganisationsLoading", false);
            }
        },
        async saveOrganisation({ commit }: { commit: Commit }, organisation: any) {
            try {
                const response = await saveOrganisation(organisation);
                commit("updateOrganisations", response.organisation);
                openSuccessNotification("Organisaatio tallennettu onnistuneesti");
            } catch {
                openErrorNotification("Organisaation lisääminen epäonnistui");
            }
        },
    },
});
