const Module = () => import("../Module.vue");
const Organisations = () =>
    import(/* webpackChunkName: "organisations" */ "../views/Organisations.vue");

const moduleRoute = {
    path: "/organisaatiot",
    name: "organisaatiot",
    component: Module,
    children: [
        {
            path: "",
            component: Organisations,
        },
    ],
    meta: {
        requiresAuth: true,
        allowedRoles: [], // admins only
    },
};

export default (router: any) => {
    router.addRoute(moduleRoute);
};
