import { Commit } from "vuex";
import { openErrorNotification, openSuccessNotification } from "@/components/Notification";
import { Kilpailutusasiakirja } from "../@types/kilpailutusasiakirja";
import {
    getKilpailutusasiakirjat,
    saveKilpailutusasiakirja,
    deleteKilpailutusasiakirja,
} from "../api/kilpailutus";

interface State {
    kilpailutusasiakirjat: Kilpailutusasiakirja[];
    loadingAsiakirjat: boolean;
}

export default {
    namespaced: true,
    state: (): State => ({
        kilpailutusasiakirjat: [],
        loadingAsiakirjat: false,
    }),
    mutations: {
        setKilpailutusasiakirjat(state: State, kilpailutusasiakirjat: Kilpailutusasiakirja[]) {
            state.kilpailutusasiakirjat = kilpailutusasiakirjat;
        },
        setLoadingAsiakirjat(state: State, loading: boolean) {
            state.loadingAsiakirjat = loading;
        },
    },
    actions: {
        async fetchKilpailutusasiakirjat({ commit }: { commit: Commit }) {
            try {
                commit("setLoadingAsiakirjat", true);
                const documents = await getKilpailutusasiakirjat();
                commit("setKilpailutusasiakirjat", documents.results);
            } catch {
                openErrorNotification("Kilpailutusasiakirjojen hakeminen epäonnistui");
            } finally {
                commit("setLoadingAsiakirjat", false);
            }
        },
        async saveKilpailutusasiakirja({ dispatch }: { dispatch: any }, kilpailutusasiakirja: any) {
            try {
                await saveKilpailutusasiakirja(kilpailutusasiakirja);
                dispatch("fetchKilpailutusasiakirjat");
                openSuccessNotification("Kilpailutusasiakirja tallennettu onnistuneesti");
            } catch {
                openErrorNotification("Kilpailutusasiakirjan tallentaminen epäonnistui");
            }
        },
        async deleteKilpailutusasiakirja({ dispatch }: { dispatch: any }, uuid: string) {
            try {
                await deleteKilpailutusasiakirja(uuid);
                dispatch("fetchKilpailutusasiakirjat");
                openSuccessNotification("Kilpailutusasiakirja poistettiin");
            } catch {
                openErrorNotification("Kilpailutusasiakirjan poistaminen epäonnistui");
            }
        },
    },
};
